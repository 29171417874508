import React from 'react';
import SEO from '../modules/layout/containers/SEO';
import {Layout} from '../modules/layout/containers/Layout';
import {useSiteMetadata} from '../modules/common/hooks/useSiteMetadata';
import {computeSEOMeta} from '../modules/core/util/SEOUtil';
import {ThankYouPageContent} from '../modules/checkout/containers/ThankYoutPageContent';

interface Props {
    location: Location;
}

export const Checkout: React.FC<Props> = ({location}) => {
    const siteMetadata = useSiteMetadata();
    const {title, metadata} = computeSEOMeta(siteMetadata, {} as any);
    return (
        <Layout location={location}>
            <SEO title={`Thank You - ${title}`} meta={metadata} />
            <ThankYouPageContent />
        </Layout>
    );
};

export default Checkout;

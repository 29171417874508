import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {Colors} from '../../../theme';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            margin: theme.spacing(4, 0),
            padding: theme.spacing(4),
            backgroundColor: Colors.LIGHTER_GRAY,
        },
        p: {
            marginTop: theme.spacing(2),
        },
    }),
);

const siteName = process.env.GATSBY_SITE_NAME;
const siteUrl = `${process.env.GATSBY_SITE_URL_PROTOCOL}://${process.env.GATSBY_SITE_URL_PATH}`;

export const ThankYouPageContent: React.FC<any> = () => {
    const classes = useStyles();
    return (
        <Container maxWidth="md">
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h2" component="h1">
                        Thank You
                    </Typography>
                </Grid>
                <Grid className={classes.content} item xs={12}>
                    <Typography variant="body1">
                        An email receipt containing information about your order will soon follow. Please keep in for
                        your records
                    </Typography>
                    <Typography className={classes.p} variant="body1">
                        Thank you for shopping at <a href={siteUrl}>{siteName}</a>{' '}
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    );
};
